import Row from "../../../../../components/baseComponents/Row";
import styled from "styled-components";
import { YardiCompanyDatabasesInterface } from "../interfaces/YardiIntegrationDetailsInterface";
import ListItem from "../../../../../components/baseComponents/ListItem";
import KeyValueRow from "../components/KeyValueElement";

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledListItem = styled(Row)`
  background-color: ${(props) => props.theme.colors.grey10};
  border-radius: ${(props) => props.theme.border_radius.SM};
`;

/**
 * @component SingleLocationDetails
 *
 * This component displays the details of a single database (i.e. location),
 * including the number of properties, used in the main DetailsSection component.
 *
 * @param dbName: string - The name of the Yardi database/location
 * @param parameters: YardiCompanyDatabasesInterface | undefined - The parameters of the database
 * @returns
 */
const SingleLocationDetails = ({
  dbName,
  parameters,
}: {
  dbName: string;
  parameters: YardiCompanyDatabasesInterface | undefined;
}) => {
  const {
    properties_qty,
    buildings,
    login,
    charge_name,
    url,
    deposit_account,
    disbursement_account,
    misc_income_account,
    default_bank_id,
    operating_month_field,
  } = parameters || {};
  return (
    <Row>
      <ListItem title={dbName} details={[`${properties_qty} properties`]} />
      <KeyValueRow label="Login" value={login} />
      <KeyValueRow label="Password" value="**** ****" />
      <KeyValueRow label="Charge Name" value={charge_name} />
      <KeyValueRow label="URL" value={url} />
      <KeyValueRow label="Deposit Account" value={deposit_account} />
      <KeyValueRow label="Disbursement Account" value={disbursement_account} />
      <KeyValueRow
        label="Miscellaneous Income Account"
        value={misc_income_account}
      />
      <KeyValueRow label="Default Bank ID" value={default_bank_id} />
      <KeyValueRow
        label="Operating Month Field"
        value={operating_month_field}
      />

      <StyledLabel>Properties:</StyledLabel>
      {buildings && buildings.length > 0
        ? buildings.map((building) => (
            <Row key={building.name}>
              <StyledListItem
                mt={{ default: 2 }}
                pb={{ default: 1 }}
                pt={{ default: 1 }}
                alignItems="center"
              >
                {building.name} <b>{building.code}</b> /{" "}
                {building.street_address}
              </StyledListItem>
            </Row>
          ))
        : "No properties available"}
    </Row>
  );
};

export default SingleLocationDetails;
