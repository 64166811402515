import { useState, useEffect } from "react";
import styled from "styled-components";
import { find } from "lodash";
import Row from "../../../../../components/baseComponents/Row";
import Div from "../../../../../components/baseComponents/Div";
import Dropdown from "../../../../../components/baseComponents/Dropdown";
import useCompanyFromParams from "../../../../utils/useCompanyFromParams";
import { Option } from "../../../../../components/global/ModelInterfaces";
import { RentManagerLocation } from "../ModelInterfaces";

interface Props {
  handleSelect: Function;
}

const LocationSelect = ({ handleSelect }: Props) => {
  const { company } = useCompanyFromParams();
  const [selectedLocation, setSelectedLocation] =
    useState<RentManagerLocation | null>(null);
  const locationOptions = company.integration_info.locations.map(
    (location: RentManagerLocation) => {
      return {
        label: location.name,
        id: location.uuid,
      };
    }
  );

  const selectLocation = (option: Option) => {
    const location = find(company.integration_info.locations, (location) => {
      return location.uuid === option.id;
    });
    setSelectedLocation(location);
  };

  useEffect(() => {
    handleSelect(selectedLocation);
  }, [selectedLocation]);

  return (
    <Row>
      <Div width={{ default: 1 }}>
        <Dropdown
          options={locationOptions}
          name="Location"
          placeholder=""
          onClick={selectLocation}
          value={
            selectedLocation
              ? {
                  label: selectedLocation.name,
                  id: selectedLocation.uuid,
                }
              : null
          }
        />
      </Div>
    </Row>
  );
};
export default LocationSelect;
