import { useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../../baseComponents/Button";
import Checkbox from "../../baseComponents/Checkbox";
import Div from "../../baseComponents/Div";
import ErrorMessage from "../../baseComponents/ErrorMessage";
import Row from "../../baseComponents/Row";
import { enrollInRenewalPlan } from "../../../utils/depositUtils";
import {
  GenericObject,
  RenewalPlanApprovalInfo,
  Deposit,
} from "../../global/ModelInterfaces";
import { currencyFormatter } from "../../global/utils";
import { useAppDispatch } from "../../../store/hooks";
import { updateTenantDeposits } from "../../../store/tenantInfo";

const ToSRow = styled(Row)`
  font-size: ${(props) => props.theme.font_size.bodySM};
`;

interface EnrollmentProps {
  approvalInfo: RenewalPlanApprovalInfo;
  setSuccessfulEnrollment: () => void;
}

const PayForEnrollment = ({
  approvalInfo,
  setSuccessfulEnrollment,
}: EnrollmentProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { prepaymentUUID } = useParams<GenericObject>();

  const [loading, setLoading] = useState(false);
  const [tosChecked, setTosChecked] = useState(false);
  const [error, setError] = useState("");

  const handleEnrollment = () => {
    const successCallback = (deposits: Deposit[]) => {
      dispatch(updateTenantDeposits(deposits));
      setSuccessfulEnrollment();
      setLoading(false);
    };
    const failureCallback = (error: string) => {
      setLoading(false);
      setError(error);
    };

    setLoading(true);
    enrollInRenewalPlan(prepaymentUUID, successCallback, failureCallback);
  };
  return (
    <Div width={{ sm: 1, md: 1, lg: 9 / 12 }}>
      <Row justifyContent="center" mt={{ default: 3 }}>
        <Div width={{ sm: 1, default: 6 / 12 }}>
          Today's total: {currencyFormatter.format(approvalInfo.enrollment_fee)}
        </Div>
      </Row>
      <Row justifyContent="center" mt={{ default: 3 }}>
        <Div width={{ sm: 1, default: 6 / 12 }}>
          {!approvalInfo.sufficient_funds_for_fee ? (
            <>
              Your bank account does not have enough funds to pay the enrollment
              fee today, please return to the dashboard and link a new bank to
              see a new offer.
              <Div mt={{ default: 3 }}>
                <Button
                  text="Back to dashboard"
                  onClick={() => history.push("/dashboard")}
                />
              </Div>
            </>
          ) : (
            <>
              {error && (
                <Row justifyContent="center" mb={{ default: 3 }}>
                  <ErrorMessage>{error}</ErrorMessage>
                </Row>
              )}
              <Button
                text="Enroll Now"
                onClick={handleEnrollment}
                loading={loading}
                disabled={!tosChecked || loading}
                onDisabledClick={() =>
                  setError("You must accept the Terms of Use before continuing")
                }
              />
              <ToSRow alignItems="center" pt={{ default: 2 }}>
                <Div>
                  <Checkbox
                    value={tosChecked}
                    onChange={() => {
                      setTosChecked((tosChecked: boolean) => !tosChecked);
                      setError("");
                    }}
                  />
                </Div>
                <Div width={{ sm: 3 / 4, md: 4 / 5, lg: 4 / 5 }}>
                  <span>
                    By clicking Enroll Now, you acknowledge and agree to
                    Rentable's{" "}
                    <a
                      href={"https://rentable.com/tenant-terms-of-services"}
                      className="secondary-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Tenant Terms of Use
                    </a>
                    , and specifically the terms for{" "}
                    <a
                      href={
                        "https://rentable.com/payment-plan-terms-of-services"
                      }
                      className="secondary-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Rentable's Renewal Payment Plan
                    </a>
                    .
                  </span>
                </Div>
              </ToSRow>
            </>
          )}
        </Div>
      </Row>
    </Div>
  );
};

export default PayForEnrollment;
