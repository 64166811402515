import { checkResponseStatus } from "./globalUtils";
import prepaymentStatuses from "../enums/prepaymentStatuses";
import axios from "axios";
import { noop } from "lodash";

export const createDeposits = async (request) => {
  const depositRequest = {
    unit_id: request.unit_uuid,
    tenant_info: request.tenants,
    start_date: request.deposit_start_date,
    end_date: request.deposit_end_date,
    month_to_month: request.month_to_month,
  };
  return axios
    .post("/api/deposit/multiple", depositRequest)
    .then((res) => {
      const data = res.data;
      return {
        new_prepayments: data.new_prepayments,
        invites: data.invites,
        errors: data.errors,
      };
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      throw new Error(
        "There was a problem creating your deposit, please try again."
      );
    });
};

export const submitDepositDelete = async (uuid, unused_uuid, history) => {
  const depositRequest = {
    prepayment_uuid: uuid,
  };
  return axios.post("/api/deposit/delete", depositRequest);
};

// This is used when LL self-funds a prepayment
export const makePayment = async (
  uuid,
  bankAccount,
  successCallback,
  failureCallback
) => {
  const bankUUID = bankAccount ? bankAccount.uuid : null;
  axios
    .post("/api/prepayments/fund", {
      prepayment_uuid: uuid,
      bank_account_uuid: bankUUID,
    })
    .then((res) => {
      const data = res.data;
      successCallback(data.updated_prepayment);
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      failureCallback(
        "There was an error funding the deposit, please try again or contact support@rentable.com if this issue persists."
      );
    });
};

// This is used when LL self-funds additional requests
export const selfFundAdditionalRequests = async (
  uuid,
  bankAccount,
  successCallback,
  failureCallback
) => {
  const bankUUID = bankAccount ? bankAccount.uuid : null;

  axios
    .post("/api/payments/prepayment/additional-requests-fund", {
      prepayment_uuid: uuid,
      bank_account_uuid: bankUUID,
    })
    .then((res) => {
      const data = res.data;
      successCallback(data.updated_prepayment);
    })
    .catch((err) => {
      checkResponseStatus(err);
      failureCallback(
        "There was an error funding the additional requests, please try again or contact support@RentableCard.com"
      );
    });
};

// This process is used in TenantOnboarding
export const makeDepositPayment = async (
  bankAccount,
  depositUUID,
  successCallback,
  failureCallback
) => {
  const bankUUID = bankAccount ? bankAccount.uuid : null;
  axios
    .post("/api/payments/deposit/fund", {
      bank_account_uuid: bankUUID,
      deposit_uuid: depositUUID,
    })
    .then((res) => {
      const data = res.data;
      successCallback(data.deposits);
      return;
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      failureCallback();
    });
};

export const makePartialPayment = async (
  uuid,
  bankAccount,
  paymentType,
  successCallback,
  failureCallback
) => {
  axios
    .post("/api/payments/deposit/partial-payment", {
      prepayment_uuid: uuid,
      bank_account_uuid: bankAccount.uuid,
      payment_type: paymentType,
    })
    .then((res) => {
      successCallback(res.data.updated_deposits);
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      failureCallback(err.data);
    });
};

export const payMonthly = async (
  depositUUID,
  successCallback,
  failureCallback
) => {
  return axios
    .post("/api/payments/deposit/pay-monthly", {
      deposit_uuid: depositUUID,
    })
    .then((res) => {
      const data = res.data;
      successCallback(data.deposits);
      return;
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      failureCallback();
    });
};

export const createPaymentIntent = (
  prepaymentUUID,
  successCallback,
  failureCallback
) => {
  axios
    .post("/api/payments/credit/deposit", { prepayment_uuid: prepaymentUUID })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      failureCallback(err);
    });
};

export const getPaymentData = async (
  prepaymentUUID,
  successCallback,
  failureCallback
) => {
  await axios
    .post("/api/payments/credit/details", { prepayment_uuid: prepaymentUUID })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      failureCallback(err);
    });
};

export const confirmPaymentIntent = async (
  paymentIntent,
  prepaymentUUID,
  successCallback,
  failureCallback
) => {
  axios
    .post("/api/payments/credit/confirmed", {
      payment_intent: paymentIntent,
      prepayment_uuid: prepaymentUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const createCardSetup = async (
  prepaymentUUID,
  successCallback,
  failureCallback
) => {
  await axios
    .post("/api/payments/credit/save", { prepayment_uuid: prepaymentUUID })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      failureCallback(err);
    });
};

export const registerMonthlyPaymentPlanClick = (
  canPayMonthly,
  prepaymentUUID
) => {
  axios.post("/api/payments/plan/interest", {
    can_pay_monthly: canPayMonthly,
    prepayment_uuid: prepaymentUUID,
  });
};

export const getPrepaymentDetails = async (
  prepaymentUUID,
  successCallback,
  errorCallback = noop
) => {
  return axios
    .get(`/api/deposit/details/${prepaymentUUID}`)
    .then(async (res) => {
      const data = await res;
      successCallback(data.data.values);
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      errorCallback(err);
    });
};

export const requestAdditionalFunds = async (
  prepaymentUUID,
  securityDepositAmount,
  lastMonthsRentAmount,
  successCallback,
  errorCallback
) => {
  return axios
    .post("/api/payments/request-additional-funds", {
      prepayment_uuid: prepaymentUUID,
      security_deposit_additional_funds_amount: securityDepositAmount,
      last_months_rent_additional_funds_amount: lastMonthsRentAmount,
    })
    .then((res) => {
      const data = res.data;
      successCallback(data.updated_prepayment);
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      errorCallback(err.message ?? err.data);
    });
};

export const deleteAdditionalFundsRequest = async (
  additionalFundsRequestUUID,
  successCallback,
  errorCallback
) => {
  await axios
    .post("/api/payments/delete-additional-funds-request", {
      addiontal_funds_request_uuid: additionalFundsRequestUUID,
    })
    .then((res) => {
      const data = res.data;
      successCallback(data.updated_prepayment);
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      errorCallback(err.message ?? err.data);
    });
};

export const fundAdditionalAmounts = async (prepaymentUUID) => {
  await axios
    .post("/api/payments/pay-additional-funds", {
      prepayment_uuid: prepaymentUUID,
    })
    .then(() => {})
    .catch((err) => {
      checkResponseStatus(err.status);
      throw new Error(err.message ?? err.data);
    });
};

export const createPartialDepositRefund = async (
  prepaymentUUID,
  securityDepositAmount,
  lastMonthsRentAmount,
  successCallback,
  errorCallback
) => {
  return axios
    .post("/api/payments/create-partial-refund", {
      prepayment_uuid: prepaymentUUID,
      security_deposit_refund_amount: securityDepositAmount,
      last_months_rent_refund_amount: lastMonthsRentAmount,
    })
    .then((res) => {
      const data = res.data;
      successCallback(data.updated_prepayment, data.refund_results);
    })
    .catch((error) => {
      checkResponseStatus(error.status);
      errorCallback(error.message ?? error.data);
    });
};

export const getPendingAdditionalFundsRequests = async (prepaymentUUID) => {
  return axios
    .get(`/api/deposit/pending-funds-requests/${prepaymentUUID}`)
    .then(async (res) => {
      return await res.data;
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      throw new Error(err);
    });
};

export const deleteTenantInvite = (invite, callback) => {
  return axios
    .post("/api/invite/tenant/delete", {
      invite_uuid: invite.uuid,
    })
    .then((res) => {
      callback(invite, res.data.deleted_prepayment);
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      throw new Error(err.message ?? err.data);
    });
};

export const sendInviteReminder = (
  invite,
  successCallback,
  failureCallback
) => {
  return axios
    .post("/api/invite/send-invite-reminder", {
      invite_uuid: invite.uuid,
    })
    .then((res) => {
      successCallback(res.data.tenant_invite);
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      const error = err.data;
      failureCallback(error);
    });
};

export const inviteLandlord = (
  email,
  firstName,
  lastName,
  successCallback,
  failureCallback
) => {
  return axios
    .post("/api/invite/landlord", {
      email: email,
      firstName: firstName,
      lastName: lastName,
    })
    .then(() => {
      successCallback();
    })
    .catch((err) => {
      failureCallback(err.data);
    });
};

export const hasPendingAdditionalFundsRequests = (deposits) => {
  if (deposits) {
    return Object.keys(deposits)
      .map((key) => {
        return deposits[key].additional_funds_requests.some((request) => {
          return request.status_id == prepaymentStatuses.PENDING;
        });
      })
      .some((pending) => {
        return pending;
      });
  }
  return false;
};

export const updatePrepaymentUnit = (
  prepaymentUUID,
  unitUUID,
  successCallback,
  errorCallback
) => {
  return axios
    .post("/api/deposit/unit-update", {
      prepayment_uuid: prepaymentUUID,
      unit_uuid: unitUUID,
    })
    .then(async (res) => {
      const data = await res.data;
      successCallback(data.updated_prepayment);
    })
    .catch((error) => {
      checkResponseStatus(error.status);
      errorCallback(error.message);
    });
};

export const updateDepositDates = (
  prepaymentUUID,
  newStartDate,
  newEndDate,
  monthToMonth,
  successCallback,
  errorCallback
) => {
  return axios
    .post("/api/deposit/update-dates", {
      prepayment_uuid: prepaymentUUID,
      new_start_date: newStartDate,
      new_end_date: newEndDate,
      month_to_month: monthToMonth,
    })
    .then(async (res) => {
      successCallback(res.data.updated_prepayment);
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      errorCallback(err.message ?? err.data);
    });
};

export const createRenewal = (
  prepaymentUUID,
  newEndDate,
  monthToMonth,
  securityDepositAmount,
  lastMonthsRentAmount,
  successCallback,
  failureCallback
) => {
  return axios
    .post("/api/renewals/create", {
      prepayment_uuid: prepaymentUUID,
      new_end_date: newEndDate,
      month_to_month: monthToMonth,
      security_deposit_amount: securityDepositAmount,
      last_months_rent_amount: lastMonthsRentAmount,
    })
    .then(async (res) => {
      successCallback(res.data.updated_prepayment);
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      failureCallback(err.message ?? err.data);
    });
};

export const enrollInRenewalPlan = async (
  prepaymentUUID,
  successCallback,
  failureCallback
) => {
  return axios
    .post("/api/payments/renewals/enroll-in-payment-plan", {
      prepayment_uuid: prepaymentUUID,
    })
    .then((res) => {
      successCallback(res.data.deposits);
    })
    .catch((err) => {
      const internalServerError = checkResponseStatus(err.status);
      if (internalServerError) {
        failureCallback(internalServerError);
      } else {
        failureCallback(err.data);
      }
    });
};
