import { useState } from "react";
import Div from "../../../../../components/baseComponents/Div";
import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import styled, { css } from "styled-components";
import ModalBig from "../../../../../components/baseComponents/ModalBig";
import Row from "../../../../../components/baseComponents/Row";
import YardiLogo from "../components/YardiLogo";
import ToggleButtonSmall from "../../../../../components/baseComponents/ToggleButtonSmall";
import HorizontalDivider from "../../../../../components/baseComponents/HorizontalDivider";

interface Props {
  ledgerData: GenericObject;
  modalOpen: boolean;
  setModalOpen: Function;
}

const StyledTitle = styled(Div)`
  font-size: 20px;
`;

const StyledTitleCurrentTenant = styled(Div)`
  font-size: 20px;
  color: green;
  font-style: italic;
`;

const StyledTile = styled(Div)`
  max-height: 70vh;
  font-size: 14px;
  overflow-x: scroll;
  overflow-y: scroll;
  padding-bottom: 1rem;
`;

const TableStyle = css`
  border: 1px solid ${(props) => props.theme.colors.black};
  border-collapse: collapse;
  padding-left: 7px;
  padding-right: 7px;
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const StyledTableRow = styled.tr`
  table-layout: fixed;
`;

const StyledTableHeader = styled.th`
  ${TableStyle}
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.colors.white};
`;
const StyledTableSection = styled.td`
  ${TableStyle}
`;
const StyledTableSectionGreen = styled.td`
  ${TableStyle}
  color: green;
`;
const StyledTableSectionYellow = styled.td`
  ${TableStyle}
  color: goldenrod;
`;
const StyledTableSectionRedHighlight = styled.td`
  ${TableStyle}
  background-color: rgba(0, 250, 154, 0.2);
`;

const LedgerModal = ({ ledgerData, modalOpen, setModalOpen }: Props) => {
  const [showJson, setShowJson] = useState(false);
  const tenantIsCurrent = ledgerData.tenant_status == "Current";

  return (
    <ModalBig
      showModal={modalOpen}
      closeModal={() => {
        setModalOpen(false);
        setShowJson(false);
      }}
    >
      <Div justifyContent="center" width={{ default: 11 / 12 }}>
        <Row justifyContent="center" mt={{ default: 2 }}>
          <Div>
            <YardiLogo />
          </Div>
        </Row>
        <Row mt={{ default: 3 }} mb={{ default: 1 }} justifyContent="center">
          <StyledTitle width={{ default: 4 / 12 }} alignItems="center">
            TenantID: {ledgerData.tenant_id}
          </StyledTitle>
          {tenantIsCurrent ? (
            <StyledTitleCurrentTenant
              width={{ default: 4 / 12 }}
              alignItems="center"
            >
              Status: {ledgerData.tenant_status}
            </StyledTitleCurrentTenant>
          ) : (
            <StyledTitle width={{ default: 4 / 12 }} alignItems="center">
              Status: {ledgerData.tenant_status}
            </StyledTitle>
          )}
          <StyledTitle width={{ default: 4 / 12 }}>
            <Row>
              <Div width={{ default: 8 / 12 }} alignItems="flex-end">
                Show Raw Data:
              </Div>
              <Div width={{ default: 4 / 12 }}>
                <ToggleButtonSmall
                  initialToggleState={showJson}
                  onToggle={() => {
                    setShowJson((prevState) => !prevState);
                  }}
                />
              </Div>
            </Row>
          </StyledTitle>
        </Row>
        <HorizontalDivider />
        {showJson ? (
          <StyledTile width={{ default: 1 }} alignItems="center">
            <pre>{ledgerData.json_pretty}</pre>
          </StyledTile>
        ) : (
          <StyledTile width={{ default: 1 }}>
            <table>
              <StyledTableRow>
                <StyledTableHeader>Trans ID</StyledTableHeader>
                <StyledTableHeader>Trans Date</StyledTableHeader>
                <StyledTableHeader>Trans Type</StyledTableHeader>
                <StyledTableHeader>Charge Code</StyledTableHeader>
                <StyledTableHeader>Charge Amount</StyledTableHeader>
                <StyledTableHeader>Is Open</StyledTableHeader>
                <StyledTableHeader>Is Posted</StyledTableHeader>
                <StyledTableHeader>Description</StyledTableHeader>
                <StyledTableHeader>Notes</StyledTableHeader>
              </StyledTableRow>
              {ledgerData.transactions && ledgerData.transactions.length > 0 ? (
                ledgerData.transactions.map((row: GenericObject) => {
                  const isCharge = row.TransType == "Charge";
                  const isRntbdepo = row.ChargeCode == "rntbdepo";
                  return (
                    <StyledTableRow>
                      <StyledTableSection>{row.TransID}</StyledTableSection>
                      <StyledTableSection>{row.TransDate}</StyledTableSection>
                      {isCharge ? (
                        <StyledTableSectionYellow>
                          {row.TransType}
                        </StyledTableSectionYellow>
                      ) : (
                        <StyledTableSectionGreen>
                          {row.TransType}
                        </StyledTableSectionGreen>
                      )}
                      {isRntbdepo ? (
                        <StyledTableSectionRedHighlight>
                          {row.ChargeCode}
                        </StyledTableSectionRedHighlight>
                      ) : (
                        <StyledTableSection>
                          {row.ChargeCode}
                        </StyledTableSection>
                      )}
                      <StyledTableSection>
                        {row.ChargeAmount}
                      </StyledTableSection>
                      <StyledTableSection>{row.isOpen}</StyledTableSection>
                      <StyledTableSection>{row.isPosted}</StyledTableSection>
                      <StyledTableSection>{row.Description}</StyledTableSection>
                      <StyledTableSection>{row.Notes}</StyledTableSection>
                    </StyledTableRow>
                  );
                })
              ) : (
                <Div>No transactions exist for tenant.</Div>
              )}
            </table>
          </StyledTile>
        )}
      </Div>
    </ModalBig>
  );
};

export default LedgerModal;
