import { find, head } from "lodash";
import { useState, useEffect } from "react";

import Div from "../../../../../components/baseComponents/Div";
import Dropdown from "../../../../../components/baseComponents/Dropdown";
import Row from "../../../../../components/baseComponents/Row";
import { Option } from "../../../../../components/global/ModelInterfaces";
import useCompanyFromParams from "../../../../utils/useCompanyFromParams";

import { YardiCompanyDatabasesInterface } from "../interfaces/YardiIntegrationDetailsInterface";
import { YardiLocationOption } from "../interfaces/YardiIntegrationDetailsInterface";

interface Props {
  handleSelect: Function;
}

const LocationSelect = ({ handleSelect }: Props) => {
  const { company } = useCompanyFromParams();
  const locations: YardiLocationOption[] = Object.keys(
    company.integration_details.yardi_db_name
  ).map((key: string) => {
    const value: YardiCompanyDatabasesInterface =
      company.integration_details.yardi_db_name[key];
    return {
      name: key,
      uuid: value.uuid,
    };
  });
  const locationOptions = locations.map((location: YardiLocationOption) => {
    return {
      label: location.name,
      id: location.uuid,
    };
  });
  const [selectedLocation, setSelectedLocation] = useState<
    YardiLocationOption | null | undefined
  >(head(locations));

  const selectLocation = (option: Option) => {
    const location = find(locations, (location) => {
      return location.uuid === option.id;
    });
    setSelectedLocation(location);
  };

  useEffect(() => {
    handleSelect(selectedLocation);
  }, [selectedLocation]);

  return (
    <Row>
      <Div width={{ default: 1 }}>
        <Dropdown
          options={locationOptions}
          name="Location"
          placeholder="Choose a location"
          onClick={selectLocation}
          value={
            selectedLocation
              ? {
                  label: selectedLocation.name,
                  id: selectedLocation.uuid,
                }
              : null
          }
        />
      </Div>
    </Row>
  );
};
export default LocationSelect;
