import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import useNucleusPermissions from "../../../../../utils/useNucleusPermissions";
import { nucleusUserPermissions } from "../../../../../enums/nucleusPermissions";
import DropdownCard from "../../../../../components/baseComponents/DropdownCard";
import useCompanyFromParams from "../../../../utils/useCompanyFromParams";
import ButtonToForm from "../../../../components/ButtonToForm";
import ResourceIDTool from "./ResourceIDTool";
import ResourceListTool from "./ResourceList";
import SecurityDepositHeld from "./SecurityDepositsHeld";
import LinkPrepaymentTool from "./LinkPrepaymentTool";
import DepositChargesTable from "../components/DepositChargesTable";
import BeatLoader from "react-spinners/BeatLoader";
import InviteProspectTool from "./InviteProspectTool";

const ToolsSection = () => {
  const { loadingPermissions, engineeringAccess, integrationsAccess } =
    useNucleusPermissions();
  const { loadingCompanies, company } = useCompanyFromParams();

  if (loadingPermissions) {
    return <BeatLoader />;
  }

  return (
    <DropdownCard title={"Rent Manager Tools"} initiallyOpen={false}>
      <Row mb={{ default: 3 }}>
        <Div width={{ default: 6 / 12 }} mb={{ default: 3 }}>
          <ButtonToForm
            formTitle="Resource ID Tool"
            buttonText="Find a Resource ID"
          >
            <ResourceIDTool />
          </ButtonToForm>
        </Div>
        {!(nucleusUserPermissions.NONE == integrationsAccess) && (
          <>
            <Div width={{ default: 6 / 12 }}>
              <ButtonToForm
                formTitle="Link Prepayment to a Rent Manager Tenant or Prospect"
                buttonText="Link Rent Manager Prepayment"
              >
                <LinkPrepaymentTool />
              </ButtonToForm>
            </Div>
          </>
        )}
        {!(nucleusUserPermissions.NONE == engineeringAccess) && (
          <>
            <Div width={{ default: 6 / 12 }}>
              <ButtonToForm
                formTitle="Create a Rent Manager Request"
                buttonText="Retrieve Resource List"
              >
                <ResourceListTool />
              </ButtonToForm>
            </Div>
          </>
        )}
        {!(nucleusUserPermissions.NONE == integrationsAccess) && (
          <>
            <Div width={{ default: 6 / 12 }}>
              <ButtonToForm
                formTitle="Invite a Rent Manager Prospect"
                buttonText="Invite Prospect"
              >
                <InviteProspectTool />
              </ButtonToForm>
            </Div>
          </>
        )}
      </Row>
      <Row>
        {loadingPermissions ? (
          <BeatLoader />
        ) : (
          engineeringAccess != nucleusUserPermissions.NONE &&
          integrationsAccess != nucleusUserPermissions.NONE && (
            <>
              <Row mb={{ default: 3 }} addSpace={false}>
                <Div width={{ lg: 1 }}>
                  <ButtonToForm
                    formTitle="Security Deposits Held Table"
                    buttonText="Security Deposits Held Table"
                  >
                    <SecurityDepositHeld rentManagerCompany={company} />
                  </ButtonToForm>
                </Div>
              </Row>
              <Row addSpace={false}>
                <Div width={{ lg: 1 }}>
                  <ButtonToForm
                    formTitle="Tenant Deposit Charges Table"
                    buttonText="Retrieve Tenant Deposit Charges"
                  >
                    <DepositChargesTable />
                  </ButtonToForm>
                </Div>
              </Row>
            </>
          )
        )}
      </Row>
    </DropdownCard>
  );
};

export default ToolsSection;
