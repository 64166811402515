import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../../store/hooks";
import styled from "styled-components";
import usePrepaymentFromParams from "../../../../utils/usePrepaymentFromParams";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import DropdownCard from "../../../../../components/baseComponents/DropdownCard";
import BarLoader from "../../../../../components/baseComponents/BarLoader";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import { getUserDefinedValues } from "../../../../utils/rentManagerUtils";
import { updatePrepaymentIntegrationInfo } from "../../../../../store/nucleusPrepayments";
import { RentManagerUserDefinedFields } from "../ModelInterfaces";
import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import { formatDateValueAsLocale } from "../../../../../components/global/utils";

const StyledSubtitle = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineSM};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const UserDefinedFields = () => {
  const dispatch = useAppDispatch();
  const { prepaymentUUID } = useParams<GenericObject>();
  const { prepayment } = usePrepaymentFromParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!prepayment.integration_info.user_defined_values) {
      setLoading(true);
      const successCallback = (details: GenericObject) => {
        dispatch(
          updatePrepaymentIntegrationInfo(
            prepaymentUUID,
            "user_defined_values",
            details
          )
        );
        setLoading(false);
      };
      const failureCallback = (errorMessage: string) => {
        setError(errorMessage);
        setLoading(false);
      };
      getUserDefinedValues(prepaymentUUID, successCallback, failureCallback);
    }
  });

  if (loading) {
    return (
      <Row justifyContent="center" mb={{ default: 5 }}>
        <BarLoader loadingText="Loading User Defined Values" />
      </Row>
    );
  }

  const userDefinedValues: RentManagerUserDefinedFields =
    prepayment.integration_info.user_defined_values;

  return (
    <DropdownCard title="User Defined Fields" initiallyOpen={false}>
      {error && (
        <Row justifyContent="center">
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      )}
      {userDefinedValues && (
        <Row>
          {Object.keys(userDefinedValues.tenantValues).length > 0 && (
            <Div width={{ lg: 8 / 12 }}>
              <StyledSubtitle mb={{ lg: 2 }}>Tenant Values</StyledSubtitle>

              <Row>
                <Div width={{ lg: 6 / 12 }}>
                  <Row>
                    <StyledLabel>Lease Start Date:</StyledLabel>{" "}
                    {userDefinedValues.tenantValues.rentableLeaseStartDate &&
                      formatDateValueAsLocale(
                        userDefinedValues.tenantValues.rentableLeaseStartDate
                      )}
                  </Row>
                  <Row>
                    <StyledLabel>Lease End Date:</StyledLabel>{" "}
                    {userDefinedValues.tenantValues.rentableLeaseEndDate &&
                      formatDateValueAsLocale(
                        userDefinedValues.tenantValues.rentableLeaseEndDate
                      )}
                  </Row>
                  <Row>
                    <StyledLabel>Security Deposit Amount:</StyledLabel>{" "}
                    {
                      userDefinedValues.tenantValues
                        .rentableSecurityDepositAmount
                    }
                  </Row>
                  <Row>
                    <StyledLabel>Payment Completed Date:</StyledLabel>{" "}
                    {userDefinedValues.tenantValues
                      .rentablePaymentCompletedDate &&
                      formatDateValueAsLocale(
                        userDefinedValues.tenantValues
                          .rentablePaymentCompletedDate
                      )}
                  </Row>
                  <Row>
                    <StyledLabel>Security Deposit Refund Amount:</StyledLabel>{" "}
                    {
                      userDefinedValues.tenantValues
                        .rentableSecurityDepositRefundAmount
                    }
                  </Row>
                  <Row>
                    <StyledLabel>Send Refund:</StyledLabel>{" "}
                    {
                      userDefinedValues.tenantValues
                        .rentableSecurityDepositSendRefund
                    }
                  </Row>
                  <Row>
                    <StyledLabel>Refund Sent:</StyledLabel>{" "}
                    {
                      userDefinedValues.tenantValues
                        .rentableSecurityDepositRefundSent
                    }
                  </Row>
                  <Row>
                    <StyledLabel>Refund Date:</StyledLabel>{" "}
                    {userDefinedValues.tenantValues
                      .rentableSecurityDepositRefundDate &&
                      formatDateValueAsLocale(
                        userDefinedValues.tenantValues
                          .rentableSecurityDepositRefundDate
                      )}
                  </Row>
                </Div>
                <Div width={{ lg: 6 / 12 }}>
                  {userDefinedValues.tenantValues.rentableDeduction1Amount && (
                    <>
                      <Row>
                        <StyledLabel>Deduction 1 Amount:</StyledLabel>{" "}
                        {
                          userDefinedValues.tenantValues
                            .rentableDeduction1Amount
                        }
                      </Row>
                      <Row>
                        <StyledLabel>Deduction 1 Type:</StyledLabel>{" "}
                        {userDefinedValues.tenantValues.rentableDeduction1Type}
                      </Row>
                      <Row>
                        <StyledLabel>Deduction 1 Description:</StyledLabel>{" "}
                        {
                          userDefinedValues.tenantValues
                            .rentableDeduction1Description
                        }
                      </Row>
                      <Row>
                        <StyledLabel>Deduction 1 File:</StyledLabel>{" "}
                        {userDefinedValues.tenantValues
                          .rentableDeduction1File || "No file uploaded"}
                      </Row>
                    </>
                  )}
                  {userDefinedValues.tenantValues.rentableDeduction2Amount && (
                    <>
                      <Row>
                        <StyledLabel>Deduction 2 Amount:</StyledLabel>{" "}
                        {
                          userDefinedValues.tenantValues
                            .rentableDeduction2Amount
                        }
                      </Row>
                      <Row>
                        <StyledLabel>Deduction 2 Type:</StyledLabel>{" "}
                        {userDefinedValues.tenantValues.rentableDeduction2Type}
                      </Row>
                      <Row>
                        <StyledLabel>Deduction 2 Description:</StyledLabel>{" "}
                        {
                          userDefinedValues.tenantValues
                            .rentableDeduction2Description
                        }
                      </Row>
                      <Row>
                        <StyledLabel>Deduction 2 File:</StyledLabel>{" "}
                        {userDefinedValues.tenantValues
                          .rentableDeduction2File || "No file uploaded"}
                      </Row>
                    </>
                  )}
                  {userDefinedValues.tenantValues.rentableDeduction3Amount && (
                    <>
                      <Row>
                        <StyledLabel>Deduction 3 Amount:</StyledLabel>{" "}
                        {
                          userDefinedValues.tenantValues
                            .rentableDeduction3Amount
                        }
                      </Row>
                      <Row>
                        <StyledLabel>Deduction 3 Type:</StyledLabel>{" "}
                        {userDefinedValues.tenantValues.rentableDeduction3Type}
                      </Row>
                      <Row>
                        <StyledLabel>Deduction 3 Description:</StyledLabel>{" "}
                        {
                          userDefinedValues.tenantValues
                            .rentableDeduction3Description
                        }
                      </Row>
                      <Row>
                        <StyledLabel>Deduction 3 File:</StyledLabel>{" "}
                        {userDefinedValues.tenantValues
                          .rentableDeduction3File || "No file uploaded"}
                      </Row>
                    </>
                  )}
                  {userDefinedValues.tenantValues.rentableDeduction4Amount && (
                    <>
                      <Row>
                        <StyledLabel>Deduction 4 Amount:</StyledLabel>{" "}
                        {
                          userDefinedValues.tenantValues
                            .rentableDeduction4Amount
                        }
                      </Row>
                      <Row>
                        <StyledLabel>Deduction 4 Type:</StyledLabel>{" "}
                        {userDefinedValues.tenantValues.rentableDeduction4Type}
                      </Row>
                      <Row>
                        <StyledLabel>Deduction 4 Description:</StyledLabel>{" "}
                        {
                          userDefinedValues.tenantValues
                            .rentableDeduction4Description
                        }
                      </Row>
                      <Row>
                        <StyledLabel>Deduction 4 File:</StyledLabel>{" "}
                        {userDefinedValues.tenantValues
                          .rentableDeduction4File || "No file uploaded"}
                      </Row>
                    </>
                  )}
                  {userDefinedValues.tenantValues.rentableDeduction4Amount && (
                    <>
                      <Row>
                        <StyledLabel>Deduction 5 Amount:</StyledLabel>{" "}
                        {
                          userDefinedValues.tenantValues
                            .rentableDeduction5Amount
                        }
                      </Row>
                      <Row>
                        <StyledLabel>Deduction 5 Type:</StyledLabel>{" "}
                        {userDefinedValues.tenantValues.rentableDeduction5Type}
                      </Row>
                      <Row>
                        <StyledLabel>Deduction 5 Description:</StyledLabel>{" "}
                        {
                          userDefinedValues.tenantValues
                            .rentableDeduction5Description
                        }
                      </Row>
                      <Row>
                        <StyledLabel>Deduction 5 File:</StyledLabel>{" "}
                        {userDefinedValues.tenantValues
                          .rentableDeduction5File || "No file uploaded"}
                      </Row>
                    </>
                  )}
                </Div>
              </Row>
            </Div>
          )}
          <Div width={{ lg: 4 / 12 }}>
            <StyledSubtitle mb={{ lg: 2 }}>Prospect Values</StyledSubtitle>
            <Row>
              <StyledLabel>Send Invitation:</StyledLabel>{" "}
              {userDefinedValues.prospectValues.rentableProspectSendInvitation}
            </Row>
            <Row>
              <StyledLabel>Invitation Sent:</StyledLabel>{" "}
              {userDefinedValues.prospectValues.rentableProspectInvitationSent}
            </Row>
            <Row>
              <StyledLabel>Invitation Accepted:</StyledLabel>{" "}
              {
                userDefinedValues.prospectValues
                  .rentableProspectInvitationAccepted
              }
            </Row>
            <Row>
              <StyledLabel>Invitation Notes:</StyledLabel>{" "}
              {userDefinedValues.prospectValues.rentableProspectInviteNotes}
            </Row>
            <Row>
              <StyledLabel>Lease Start Date:</StyledLabel>{" "}
              {userDefinedValues.prospectValues
                .rentableProspectLeaseStartDate &&
                formatDateValueAsLocale(
                  userDefinedValues.prospectValues
                    .rentableProspectLeaseStartDate
                )}
            </Row>
            <Row>
              <StyledLabel>Lease End Date:</StyledLabel>{" "}
              {userDefinedValues.prospectValues.rentableProspectLeaseEndDate &&
                formatDateValueAsLocale(
                  userDefinedValues.prospectValues.rentableProspectLeaseEndDate
                )}
            </Row>
            <Row>
              <StyledLabel>Security Deposit Amount:</StyledLabel>{" "}
              {
                userDefinedValues.prospectValues
                  .rentableProspectSecurityDepositAmount
              }
            </Row>
            <Row>
              <StyledLabel>Payment Completed Date:</StyledLabel>{" "}
              {userDefinedValues.prospectValues
                .rentableProspectPaymentCompletedDate &&
                formatDateValueAsLocale(
                  userDefinedValues.prospectValues
                    .rentableProspectPaymentCompletedDate
                )}
            </Row>
          </Div>
        </Row>
      )}
    </DropdownCard>
  );
};

export default UserDefinedFields;
