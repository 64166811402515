import { useState } from "react";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import Input from "../../../components/baseComponents/Input";
import Button from "../../../components/baseComponents/Button";
import { useAppDispatch } from "../../../store/hooks";
import { updatePrepaymentSection } from "../../../store/nucleusPrepayments";
import { GenericObject } from "../../../components/global/ModelInterfaces";
import { updateUserEmail } from "../../utils/prepaymentUtils";
import ErrorMessage from "../../../components/baseComponents/ErrorMessage";
import { useParams } from "react-router-dom";
import SuccessMessage from "../../../components/baseComponents/SuccessMessage";

const ChangeUserEmailForm = () => {
  const dispatch = useAppDispatch();
  const { prepaymentUUID } = useParams<GenericObject>();
  const [newEmail, setNewEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleUpdateUserEmail = () => {
    const successCallback = (generalInfo: GenericObject) => {
      setLoading(false);
      setError("");
      setSuccess(`Successfully changed email to ${generalInfo.email}`);
      dispatch(
        updatePrepaymentSection(prepaymentUUID, "general_info", generalInfo)
      );
    };
    const failureCallback = (err: string) => {
      setLoading(false);
      setSuccess("");
      setError(err);
    };

    setLoading(true);
    setError("");
    setSuccess("");
    updateUserEmail(prepaymentUUID, newEmail, successCallback, failureCallback);
  };

  return (
    <Div mt={{ default: 3 }}>
      <Row mb={{ default: 3 }}>
        <Div width={{ default: 1 }}>
          <Input
            value={newEmail}
            placeholderText=""
            label="New Email"
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </Div>
      </Row>
      <Row justifyContent="center" mt={{ default: 3 }}>
        <Div width={{ default: 8 / 12 }}>
          <Button
            type="secondary"
            text="Submit"
            onClick={() => handleUpdateUserEmail()}
            disabled={loading}
          />
        </Div>
      </Row>
      {error && (
        <Row justifyContent="center" mb={{ default: 3 }}>
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      )}
      {success && (
        <Row justifyContent="center" mb={{ default: 3 }}>
          <SuccessMessage>{success}</SuccessMessage>
        </Row>
      )}
    </Div>
  );
};

export default ChangeUserEmailForm;
