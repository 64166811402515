import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GenericObject,
  RenewalPlanApprovalInfo,
} from "../components/global/ModelInterfaces";
import { checkRenewalPlanApproval } from "./accountUtils";
import useGetBankAccount from "./useGetBankAccount";
import responseStatuses from "../enums/responseStatuses";

const UseCheckRenewalApproval = () => {
  const history = useHistory();
  const { bankAccount, loadingBankAccounts } = useGetBankAccount();
  const { prepaymentUUID } = useParams<GenericObject>();
  const [approvalInfoError, setApprovalInfoError] = useState<string>("");
  const [loadingApprovalInfo, setLoadingApprovalInfo] = useState<boolean>(true);
  const [approvalInfo, setApprovalInfo] =
    useState<null | RenewalPlanApprovalInfo>(null);
  const [isApproved, setIsApproved] = useState(false);
  const [noBankAccount, setNoBankAccount] = useState(false);

  useEffect(() => {
    if (!loadingBankAccounts) {
      if (bankAccount) {
        const successCallback = (
          isApproved: boolean,
          approvalInfo: RenewalPlanApprovalInfo
        ) => {
          setIsApproved(isApproved);
          setApprovalInfo(approvalInfo);
          setLoadingApprovalInfo(false);
        };
        const failureCallback = (err: GenericObject) => {
          if (err.status === responseStatuses.REQUIRES_PLAID_UPDATE) {
            history.push({
              pathname: "/update-bank-credentials",
              state: {
                returnUrl: "/dashboard",
                successUrl: `/dashboard/tenant/renewal-plan-details/${prepaymentUUID}`,
              },
            });
          } else {
            setApprovalInfoError(
              "Something went wrong. Please try again in a few minutes. If this problem persists please contact support@rentable.com"
            );
          }
          setLoadingApprovalInfo(false);
        };
        checkRenewalPlanApproval(
          bankAccount?.uuid,
          prepaymentUUID,
          successCallback,
          failureCallback
        );
      } else {
        setNoBankAccount(true);
        setLoadingApprovalInfo(false);
      }
    }
  }, [bankAccount]);

  if (approvalInfoError) {
    setApprovalInfo(null);
    setIsApproved(false);
  }

  return {
    approvalInfo,
    isApproved,
    loadingApprovalInfo,
    approvalInfoError,
    noBankAccount,
  };
};

export default UseCheckRenewalApproval;
