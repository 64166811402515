import { useState } from "react";
import styled from "styled-components";
import Button from "../../../../../components/baseComponents/Button";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import Input from "../../../../../components/baseComponents/Input";
import LocationSelect from "../components/LocationSelect";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";

import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import { RentManagerLocation } from "../ModelInterfaces";
import { inviteProspect } from "../../../../utils/rentManagerUtils";
import SuccessMessage from "../../../../../components/baseComponents/SuccessMessage";
import { capitalize } from "lodash";

const StyledInstructions = styled(Div)`
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const InviteProspectTool = () => {
  const [selectedLocation, setSelectedLocation] =
    useState<RentManagerLocation | null>(null);
  const [prospectEmail, setProspectEmail] = useState("");
  const [prospectID, setProspectID] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorDetails, setErrorDetails] = useState<Array<GenericObject> | null>(
    null
  );
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = () => {
    if (!selectedLocation || !prospectEmail) {
      setErrorMessage("You must choose a location and enter a prospect email.");
      return;
    }
    const successCallBack = (email: string, prospectID: string) => {
      setLoading(false);
      setErrorMessage("");
      setErrorDetails(null);
      setProspectEmail("");
      setProspectID("");
      setSuccessMessage(
        `Invite successfully sent to ${email}${
          prospectID ? ` with ID: ${prospectID}` : ""
        }!`
      );
    };
    const failureCallback = (
      message: string,
      errorDetails: Array<GenericObject>
    ) => {
      setLoading(false);
      setErrorMessage(message);
      setErrorDetails(errorDetails);
      setSuccessMessage("");
    };
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");
    setErrorDetails(null);
    inviteProspect(
      selectedLocation.uuid,
      prospectEmail,
      prospectID,
      successCallBack,
      failureCallback
    );
  };

  return (
    <Div>
      <Row>
        <StyledInstructions mb={{ default: 3 }}>
          Choose Location of Prospect:
        </StyledInstructions>
      </Row>
      <LocationSelect
        handleSelect={(selectedLocation: RentManagerLocation) => {
          setSelectedLocation(selectedLocation);
        }}
      />
      <Row mb={{ default: 3 }}>
        <Div width={{ default: 1 }}>
          <Input
            value={prospectEmail}
            placeholderText=""
            onChange={(e) => setProspectEmail(e.target.value)}
            label="Prospect Email"
          />
        </Div>
      </Row>
      <Row mb={{ default: 3 }}>
        <Div width={{ default: 1 }}>
          <Input
            value={prospectID}
            placeholderText="** Use to specify prospect when multiple matches **"
            onChange={(e) => setProspectID(e.target.value)}
            label="Prospect ID (** NOT REQUIRED **)"
            type="number"
          />
        </Div>
      </Row>
      {successMessage && (
        <Row justifyContent="center" mb={{ default: 3 }}>
          <SuccessMessage>{successMessage}</SuccessMessage>
        </Row>
      )}
      {errorMessage && (
        <Div justifyContent="center" mb={{ default: 3 }}>
          <Row justifyContent="center">
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </Row>
          {errorDetails &&
            errorDetails.length > 0 &&
            errorDetails.map((errorDetail) => {
              const inviteSent = errorDetail.invite_sent;
              const inviteReady = errorDetail.invite_ready;
              return (
                <Row justifyContent="center">
                  <Div>
                    ProspectID: {errorDetail.prospect_id}, UnitID:{" "}
                    {errorDetail.unit_id ?? "None"}
                  </Div>
                  {inviteSent && <Div>Already Sent Invite</Div>}
                  {!inviteSent &&
                    (inviteReady ? (
                      <SuccessMessage>
                        Invite Ready: {capitalize(errorDetail.invite_ready)}
                      </SuccessMessage>
                    ) : (
                      <ErrorMessage>
                        Invite Ready: {capitalize(errorDetail.invite_ready)}
                      </ErrorMessage>
                    ))}
                </Row>
              );
            })}
        </Div>
      )}
      <Row>
        <Div width={{ default: 1 }}>
          <Button
            loading={loading}
            text="Invite Prospect"
            onClick={handleSubmit}
          />
        </Div>
      </Row>
    </Div>
  );
};

export default InviteProspectTool;
