import responseStatuses from "../enums/responseStatuses";

export const getQueryParamsFromString = (searchString) => {
  const searchDict = {};
  if (searchString) {
    // remove leading question mark if it is there
    if (searchString.substring(0, 1) === "?") {
      searchString = searchString.substring(1);
    }
    const queryArray = searchString.split("&");

    queryArray.forEach((query) => {
      const queryParts = query.split("=");
      searchDict[queryParts[0]] = queryParts[1];
    });
  }
  return searchDict;
};

export const checkResponseStatus = (status) => {
  if (status === responseStatuses.INTERNAL_SERVER_ERROR) {
    return "There was an issue processing your request, please refresh the page and try again. If this issue persists please contact support@rentable.com";
  } else if (status === responseStatuses.FORBIDDEN) {
    window.location.href = "/forbidden";
  } else if (status === responseStatuses.MISSING) {
    window.location.href = "/404";
  }
};

export const PROCESSOR_TRANSFER_LIMIT = 15000;

export const capitlizeString = (string) => {
  if (!string) {
    return "";
  }
  const names = string.split(" ");
  const capitalNames = names.map(
    (name) => name.charAt(0).toUpperCase() + name.slice(1)
  );
  return capitalNames.join(" ");
};

export const getStreetAddress = (address_1, address_2) => {
  let street_address = address_1;
  if (address_2) {
    street_address += ", " + address_2;
  }

  return street_address;
};
