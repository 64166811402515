import { useState, useEffect } from "react";
import styled from "styled-components";
import Div from "../../components/baseComponents/Div";
import NucleusLoader from "./NucleusLoader";
import CreateIntegration from "./Forms/CreateIntegration";
import RentManagerTab from "../pages/Integrations/rent_manager/RentManagerCompanyTab";
import useCompanyFromParams from "../utils/useCompanyFromParams";
import integrationType from "../../enums/integrationType";
import YardiTab from "../pages/Integrations/yardi/YardiTab";

const StyledLoading = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const IntegrationTab = () => {
  const { loadingCompanies, company } = useCompanyFromParams();
  const [companyIntegration, setCompanyIntegration] = useState(
    integrationType.NONE
  );

  useEffect(() => {
    if (company) {
      setCompanyIntegration(company.integration_type);
    }
  }, [company.integration_type, company]);

  if (loadingCompanies) {
    return (
      <StyledLoading justifyContent="center" alignItems="center">
        <Div width={{ default: 3 / 12 }}>
          <NucleusLoader loadingText="Loading Integration Details..." />
        </Div>
      </StyledLoading>
    );
  }

  const renderIntegration = () => {
    switch (companyIntegration) {
      case integrationType.NONE:
      default:
        return <CreateIntegration />;
      case integrationType.RENT_MANAGER:
        return <RentManagerTab />;
      case integrationType.YARDI:
        return <YardiTab />;
    }
  };

  return <>{renderIntegration()}</>;
};

export default IntegrationTab;
