import DropdownCard from "../../../../../components/baseComponents/DropdownCard";
import { DetailsSectionInterface } from "../interfaces/DetailsSectionInterface";
import DatabaseDetails from "../components/SingleLocationDetails";

interface Props {
  yardiCompanyInfo: DetailsSectionInterface;
  initiallyOpen?: boolean | undefined;
}

/**
 * @component LocationsInfoSection
 *
 * This component displays the details of a single database (i.e. location),
 * including the number of properties, used in the main DetailsSection component.
 **/
const LocationsInfoSection = ({ yardiCompanyInfo, initiallyOpen }: Props) => {
  // Count the number of locations, usually it is live and test locations,
  // but it can be more if the company has multiple databases.
  const locations_count = yardiCompanyInfo.integration_details?.yardi_db_name
    ? Object.keys(yardiCompanyInfo.integration_details.yardi_db_name).length
    : 0;

  return (
    <DropdownCard
      title={`Locations (${locations_count})`}
      initiallyOpen={initiallyOpen || false}
    >
      {yardiCompanyInfo.integration_details?.yardi_db_name &&
      Object.keys(yardiCompanyInfo.integration_details.yardi_db_name).length > 0
        ? Object.entries(
            yardiCompanyInfo.integration_details.yardi_db_name
          ).map(([dbName, details]) => (
            <DatabaseDetails
              key={dbName}
              dbName={dbName}
              parameters={details}
            />
          ))
        : "No database available"}
    </DropdownCard>
  );
};

export default LocationsInfoSection;
