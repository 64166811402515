import styled from "styled-components";
import Div from "../../../../../components/baseComponents/Div";
import { DetailsSectionInterface } from "../interfaces/DetailsSectionInterface";
import DropdownCard from "../../../../../components/baseComponents/DropdownCard";
import KeyValueRow from "../components/KeyValueElement";

const StyledItalic = styled.span`
  font-style: italic;
`;

interface Props {
  yardiCompanyInfo: DetailsSectionInterface;
}

/**
 * @component GeneralInfoSection
 *
 * Main component for the Details section of the Yardi company integration.
 *
 * Displays the details of the Yardi company, its manager, email, address, and locations.
 * Locations include the database name, login, password, charge name, URL, deposit account,
 * disbursement account, miscellaneous income account, default bank ID, and operating month field.
 *
 * For each location is available, all properties of each location are displayed,
 * including a name, code, and street address in the <DatabaseDetails> component.
 *
 * */
const GeneralInfoSection = ({ yardiCompanyInfo }: Props) => {
  const companyFullAddress = `${yardiCompanyInfo.integration_details?.street_address}, ${yardiCompanyInfo.integration_details?.city}, ${yardiCompanyInfo.integration_details?.state}, ${yardiCompanyInfo.integration_details?.zip_code}`;

  return (
    <DropdownCard title="Yardi Company Details">
      <Div width={{ default: 12 / 12 }}>
        <KeyValueRow
          label="Yardi Company Name"
          value={yardiCompanyInfo.integration_details?.company_name}
        />
        <KeyValueRow
          label="Yardi Company Code"
          value={yardiCompanyInfo.integration_details?.yardi_company_code}
        />
        <KeyValueRow
          label="Yardi Company UUID"
          value={<StyledItalic>{yardiCompanyInfo.company?.uuid}</StyledItalic>}
        />
        <KeyValueRow
          label="Company Manager Name"
          value={`${
            yardiCompanyInfo.integration_details?.manager_first_name || ""
          } ${yardiCompanyInfo.integration_details?.manager_last_name || ""}`}
        />
        <KeyValueRow
          label="Yardi Company Email"
          value={yardiCompanyInfo.integration_details?.yardi_company_email}
        />
        <KeyValueRow label="Yardi Company Address" value={companyFullAddress} />
        <KeyValueRow
          label="Invite Link"
          value={
            yardiCompanyInfo.integration_details?.invite_link ? (
              <a href={yardiCompanyInfo.integration_details.invite_link}>
                {yardiCompanyInfo.integration_details.invite_link.substring(
                  0,
                  60
                )}
                ...
              </a>
            ) : (
              "No invite link available"
            )
          }
        />
        <KeyValueRow
          label="Note"
          value={
            <StyledItalic>
              {yardiCompanyInfo.integration_details?.company_comment}
            </StyledItalic>
          }
        />
      </Div>
    </DropdownCard>
  );
};
export default GeneralInfoSection;
