import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Div from "./Div";
import Row from "./Row";
import Button from "./Button";
import SadCoin from "../../images/SadCoin";

const StyledTitle = styled.span`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

interface Props {
  message?: string;
}

const ContactSupportView = ({ message }: Props) => {
  const history = useHistory();
  return (
    <Row justifyContent="center">
      <Div alignItems="center" width={{ lg: 6 / 12 }} textAlign="center">
        <SadCoin />
        <StyledTitle>Sorry! Something went wrong.</StyledTitle>
        {message && <h3>{message}</h3>}
        <h3>
          Please reach out to
          <a href="mailto:support@rentable.com"> support@rentable.com</a> and we
          will help you address the issue.
        </h3>
        <Div width={{ lg: 8 / 12 }}>
          <Button
            text="Go Back"
            type="primary"
            onClick={() => {
              history.goBack();
            }}
          />
        </Div>
      </Div>
    </Row>
  );
};

export default ContactSupportView;
