import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import DropdownCard from "../../../../../components/baseComponents/DropdownCard";
import ButtonToForm from "../../../../components/ButtonToForm";
import LedgerSelect from "./LedgerSelect";

const ToolsSection = () => {
  return (
    <DropdownCard title={"Yardi Tools"} initiallyOpen={false}>
      <Row mb={{ default: 3 }}>
        <Div width={{ default: 6 / 12 }} mb={{ default: 3 }}>
          <ButtonToForm formTitle="View Tenant Ledger" buttonText="Get Ledger">
            <LedgerSelect />
          </ButtonToForm>
        </Div>
      </Row>
    </DropdownCard>
  );
};

export default ToolsSection;
