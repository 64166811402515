import styled from "styled-components";
import Row from "../../../../../components/baseComponents/Row";

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

interface KeyValueRowInterface {
  label: string;
  value: React.ReactNode;
}

/**
 * @component KeyValueRow
 *
 * This component displays a single key-value row.
 * TODO: Refactor to make the KeyValueRow component to be editable.
 */
const KeyValueRow = ({ label, value }: KeyValueRowInterface) => (
  <Row>
    <StyledLabel>{label}:</StyledLabel>
    {value}
  </Row>
);

export default KeyValueRow;
