import axios from "axios";

export const retrieveCompanyByUUID = (
  uuid: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/retrieve-company-by-uuid";

  axios
    .post(url, {
      uuid: uuid,
    })
    .then((resp) => {
      const data = resp.data;
      successCallback(data);
    })
    .catch((error) => {
      failureCallback(error.data);
    });
};

export const retrieveCompanyList = (
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/retrieve-company-list";

  axios
    .post(url)
    .then((resp) => {
      const data = resp.data;
      successCallback(data.companies);
    })
    .catch((error) => {
      failureCallback(error.data);
    });
};

export const inviteLandlordToCompany = (
  successCallback: Function,
  failureCallback: Function,
  companyUUID: number,
  sendEmail: boolean,
  isAdmin: boolean,
  email: string,
  firstName: string,
  lastName: string
) => {
  const url = "/nucleus/api/invite-landlord-to-company";

  axios
    .post(url, {
      company_uuid: companyUUID,
      email: email,
      first_name: firstName,
      last_name: lastName,
      send_email: sendEmail,
      is_admin: isAdmin,
    })
    .then((resp) => {
      const data = resp.data;
      successCallback(data);
    })
    .catch((error) => {
      failureCallback(error.data);
    });
};

export const changeCompanySettings = (
  updatedSetting: Object,
  successCallback: Function,
  failureCallback: Function
) => {
  axios
    .post("/nucleus/api/change-company-settings", updatedSetting)
    .then((res) => {
      const data = res.data;
      successCallback(data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const createCompany = (
  name: string,
  streetAddress: string,
  city: string,
  state: string,
  zipCode: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/create-company";

  axios
    .post(url, {
      name: name,
      street_address: streetAddress,
      city: city,
      state: state,
      zip_code: zipCode,
    })
    .then((resp) => {
      const data = resp.data;
      successCallback(data);
    })
    .catch((error) => failureCallback(error.data.error));
};

export const getSecurityDepositsHeld = (
  requestInfo: Object,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/get-security-deposits-held";
  axios
    .post(url, requestInfo)
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const offboardCompany = (
  successCallback: Function,
  failureCallback: Function,
  companyUUID: string
) => {
  const url = "/nucleus/api/company/offboard";
  axios
    .post(url, { company_uuid: companyUUID })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const offboardBuilding = (
  successCallback: Function,
  failureCallback: Function,
  buildingUUID: string
) => {
  const url = "/nucleus/api/company/offboard";
  axios
    .post(url, { building_uuid: buildingUUID })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const confirmOffboardingAmount = (
  successCallback: Function,
  failureCallback: Function,
  buildingUUID: string | null,
  companyUUID: string | null
) => {
  const url = "/nucleus/api/company/confirm-offboard";
  axios
    .post(url, { building_uuid: buildingUUID, company_uuid: companyUUID })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const uploadSureReport = (
  report: File,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/upload-sure-report";
  const formData = new FormData();
  formData.append("file", report);

  axios
    .post(url, formData)
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err.data);
    });
};

export const retrieveTenantDepositCharges = (
  tenantID: string,
  locationUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/retrieve-tenant-deposit-charges";
  const requestInfo = {
    rent_manager_tenant_id: tenantID,
    rent_manager_location_uuid: locationUUID,
  };
  axios
    .post(url, requestInfo, { timeout: 30 * 1000 })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err.data);
    });
};
