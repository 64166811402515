import { useHistory, useParams } from "react-router-dom";
import Div from "../../baseComponents/Div";
import Button from "../../baseComponents/Button";
import RentableCard from "../../baseComponents/RentableCard";
import { GenericObject } from "../../global/ModelInterfaces";

const RenewalOfferCard = () => {
  const history = useHistory();
  const { prepaymentUUID } = useParams<GenericObject>();
  return (
    <RentableCard title="* Renewal Offer *">
      <Div justifyContent="center">
        Your landlord has renewed your lease recently and you may be eligible to
        access your Security Deposit funds today!
        <Div mt={{ default: 4 }}>
          <Button
            text="Learn More"
            onClick={() => {
              history.push(`/tenant/renewal-plan-details/${prepaymentUUID}`);
            }}
          />
        </Div>
      </Div>
    </RentableCard>
  );
};
export default RenewalOfferCard;
