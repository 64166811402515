import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import DropdownCard from "../../../../../components/baseComponents/DropdownCard";
import LocationDetail from "./LocationDetail";

import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import { RentManagerLocation } from "../ModelInterfaces";

interface Props {
  details: GenericObject;
}
const LocationsSection = ({ details }: Props) => {
  return (
    <DropdownCard title={"Locations Details"} initiallyOpen={false}>
      <Div width={{ default: 12 / 12 }}>
        <Row alignItems="flex-start" justifyContent="flex-start">
          {details.locations.map((location: RentManagerLocation) => {
            return <LocationDetail location={location} />;
          })}
        </Row>
      </Div>
    </DropdownCard>
  );
};

export default LocationsSection;
