import axios from "axios";
import { GenericObject } from "../../components/global/ModelInterfaces";

export const uploadYardiDepositCSV = async (
  csv: File,
  companyId: string | undefined,
  locationID: string | undefined,
  successCallback: Function,
  failureCallback: Function
) => {
  const csvData = new FormData();
  csvData.append("file", csv);

  const url = "/nucleus/api/yardi/yardi-deposits-import";
  axios
    .post(url, csvData, {
      headers: { company_id: companyId, location_id: locationID },
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err.data);
    });
};

// This function retrieves all the Yardi companies from the Rentable Company table
export const retrieveYardiCompanies = async (
  successCallback: Function,
  failureCallback: Function
) => {
  axios
    .get("/nucleus/api/yardi/yardi-get-companies")
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

// This function retrieves a single Yardi company details from the Rentable Company table
export const retrieveYardiCompany = async (
  companyUUID: string, // This is the UUID of the Yardi linked company on Rentable Company table
  successCallback: Function,
  failureCallback: Function
) => {
  axios
    .get("/nucleus/api/yardi/yardi-get-companies/" + companyUUID)
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const retrieveYardiDbAccounts = async (
  yardiCompanyData: GenericObject,
  successCallback: Function,
  failureCallback: Function
) => {
  axios
    .post("/nucleus/api/yardi/yardi-retrieve-db-accounts", yardiCompanyData)
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const createYardiCompany = async (
  yardiCompanyData: GenericObject,
  successCallback: Function,
  failureCallback: Function
) => {
  axios
    .post("/nucleus/api/yardi/yardi-company-create", yardiCompanyData)
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const retrieveTenantLedger = async (
  tenantId: string,
  locationDB: string,
  companyCode: string,
  successCallback: Function,
  failureCallback: Function
) => {
  axios
    .post("/nucleus/api/yardi/yardi-tenant-ledger", {
      tenant_id: tenantId,
      location_db: locationDB,
      company_code: companyCode,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data.error);
    });
};
