import { useEffect, useState } from "react";
import styled from "styled-components";
import Div from "./Div";

// label extends input trigger area
const Label = styled.label`
  display: flex;
  gap: 10px;
  cursor: pointer;
`;

const ToggleSwitch = styled(Div)`
  margin-top: 5px;
  position: relative;
  width: 2em;
  height: 1em;
  border-radius: ${(props) => props.theme.border_radius.LG};
  background-color: ${(props) => props.theme.colors.grey40};
  transition: background-color 0.3s ease;
  cursor: pointer;
  transition: 300ms all;

  /* switch lever */
  &:before {
    content: "";
    position: absolute;
    width: 0.7em;
    height: 0.7em;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: ${(props) => props.theme.border_radius.LG};
    left: 8%;
    transform: translate(5%, 20%);
    transition: 300ms all;
  }
`;

const ToggleInput = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${ToggleSwitch} {
    background-color: ${(props) => props.theme.colors.backgroundTertiary};

    &:before {
      transform: translate(132%, 20%);
    }
  }
`;

interface Props {
  initialToggleState: boolean;
  onToggle: (val: boolean) => void;
}

const ToggleButtonSmall = ({ initialToggleState, onToggle }: Props) => {
  const [checked, setChecked] = useState(initialToggleState);

  return (
    <Label>
      <ToggleInput
        checked={checked}
        type="checkbox"
        onClick={() => {
          onToggle(!checked);
          setChecked((oldChecked) => !oldChecked);
        }}
      />
      <ToggleSwitch />
    </Label>
  );
};

export default ToggleButtonSmall;
