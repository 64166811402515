// Libraries
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Global
import { GenericObject } from "../../../../components/global/ModelInterfaces";
import { useAppDispatch } from "../../../../store/hooks";
import { updateCompanySection } from "../../../../store/nucleusCompanies";
import useCompanyFromParams from "../../../utils/useCompanyFromParams";

// Common components
import ErrorMessage from "../../../../components/baseComponents/ErrorMessage";
import Row from "../../../../components/baseComponents/Row";
import Container from "../../../../components/baseComponents/Container";

// Yardi specific
import YardiStyledLogo from "./components/YardiLogo";
import NucleusLoader from "../../../components/NucleusLoader";
import { YardiOption } from "./interfaces/YardiCompanyInterface";
import { YardiIntegrationDisplayInterface } from "./interfaces/YardiIntegrationDisplayInterface";
import { retrieveYardiCompany } from "../../../utils/yardiUtils";

// Sections
import GeneralInfoSection from "./CompanyTabSections/GeneralInfoSection";
import LocationsInfoSection from "./CompanyTabSections/LocationsInfoSection";
import ToolsSection from "./CompanyTabSections/ToolsSection";

/**
 * @component YardiTab
 *
 * The main Yardi Company Integration details page.
 *
 * Shows a general overview of the Yardi integration options
 * and allows managing Yardi companies and settings.
 * Company details are retrieved from the backend based on the companyUUID in the url,
 * then stored in the redux store
 *
 */

// Interface of the data retrieved from backend
interface YardiIntegrationProps {
  company: YardiOption;
  company_details: YardiIntegrationDisplayInterface;
}


const YardiTab = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { companyUUID } = useParams<GenericObject>(); // from URL
  const { loadingCompanies, company, errorLoading, errorMessage } =
    useCompanyFromParams();

  useEffect(() => {
    // Retrieves the Yardi company specific details
    getYardiCompanyDetailedInfo();
  }, [company, companyUUID]);

  const getYardiCompanyDetailedInfo = () => {
    /**
     * Retrieves updated information about the Yardi company
     **/

    const successCallback = (updatedCompanyDetails: YardiIntegrationProps) => {
      // Updates the integration section with the new information
      dispatch(
        updateCompanySection(
          companyUUID,
          "integration_details",
          updatedCompanyDetails.company_details.company_info
        )
      );
      setError("");
      setLoading(false);
    };

    const failureCallback = (error: any) => {
      setError(error.data?.message || error.statusText);
      setLoading(false);
    };

    setLoading(true);
    retrieveYardiCompany(companyUUID || "", successCallback, failureCallback);
  };

  if (loading || loadingCompanies) {
    return (
      <Container>
        <YardiStyledLogo />
        <Row justifyContent="center">
          <NucleusLoader loadingText="Retrieving Yardi Company details..." />
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <YardiStyledLogo />
      {/* Collection of components */}
      {!error && (
        <>
          <GeneralInfoSection yardiCompanyInfo={company} />
          <LocationsInfoSection
            yardiCompanyInfo={company}
            initiallyOpen={false}
          />
          <ToolsSection />
        </>
      )}

      {/* Errors display section */}
      {(error || errorLoading) && (
        <Row justifyContent="center">
          <ErrorMessage>
            {error} {errorMessage}
          </ErrorMessage>
        </Row>
      )}
    </Container>
  );
};

export default YardiTab;
