import { useState } from "react";
import { History } from "history";
import { Redirect, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../components/global/theme";
import Button from "../../baseComponents/Button";
import CalendarIcon from "../../../images/CalendarIcon";
import PayForEnrollment from "./PayForEnrollment";
import ConfusedCoin from "../../../images/ConfusedCoin";
import Div from "../../baseComponents/Div";
import ErrorMessage from "../../baseComponents/ErrorMessage";
import HoorayCoin from "../../../images/HoorayCoin";
import PageLoader from "../../PageLoader";
import Row from "../../baseComponents/Row";
import SadCoin from "../../../images/SadCoin";
import useCheckRenewalApproval from "../../../utils/useCheckRenewalApproval";
import {
  GenericObject,
  RenewalPlanApprovalInfo,
} from "../../global/ModelInterfaces";
import { currencyFormatter } from "../../global/utils";
import { cssBreakpoints } from "../../global/theme";

const RenewalPlan = styled(Div)`
  border: 1px solid ${(props) => props.theme.colors.navy};
  border-radius: ${(props) => props.theme.border_radius.MD};
  padding: 1rem;
  ${cssBreakpoints("height", [{ default: "auto" }, { lg: "100%" }])}
  ${cssBreakpoints("margin-bottom", [
    { sm: "1rem" },
    { md: "1rem" },
    { lg: "0" },
  ])}
`;
const InfoBox = styled(Div)`
  background: ${(props) => props.theme.colors.grey2};
  border-radius: ${(props) => props.theme.border_radius.SM};
  min-height: 8em;
`;

const BoldText = styled.span`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  font-size: ${(props) => props.theme.font_size.headlineXS};
`;
const Price = styled(Div)`
  color: ${(props) => props.theme.colors.primary};
  display: inline;
  & > span {
    font-weight: ${(props) => props.theme.font_weight.bolder};
    font-size: ${(props) => props.theme.font_size.headlineLG};
  }
  text-align: center;
`;

const MonthlyPaymentSpan = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;
const MonthlyPaymentInfo = styled(Row)`
  font-size: ${(props) => props.theme.font_size.bodyMD};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const Details = () => {
  const history = useHistory();
  const { prepaymentUUID } = useParams<GenericObject>();
  const [enrollmentSuccess, setEnrollmentSuccess] = useState(false);

  const {
    approvalInfo,
    isApproved,
    loadingApprovalInfo,
    approvalInfoError,
    noBankAccount,
  } = useCheckRenewalApproval();

  if (loadingApprovalInfo) {
    return <LoadingSection />;
  } else if (noBankAccount) {
    return <NoBankSection history={history} />;
  } else if (approvalInfoError) {
    return <ApprovalInfoError errorMessage={approvalInfoError} />;
  } else if (!approvalInfo) {
    return <Redirect to={`/tenant/renewal-plan-intro/${prepaymentUUID}`} />;
  } else if (!isApproved) {
    return <UnapprovedSection history={history} />;
  } else if (enrollmentSuccess) {
    return <EnrollmentSuccess history={history} />;
  } else {
    return (
      <EnrollmentDetailsSection
        approvalInfo={approvalInfo}
        setSuccessfulEnrollment={() => setEnrollmentSuccess(true)}
      />
    );
  }
};

const LoadingSection = () => {
  return (
    <Div alignItems="center">
      <PageLoader />
      <Div alignItems="center">Loading your offer...</Div>
    </Div>
  );
};
interface HistoryProps {
  history: History;
}

const NoBankSection = ({ history }: HistoryProps) => {
  return (
    <Div width={{ default: 4 / 12 }} alignItems="center">
      <Row justifyContent="center">
        <Div>
          <ConfusedCoin />
        </Div>
      </Row>
      <Row mt={{ default: 3 }}>
        It looks like you haven't linked a bank account with us yet.
      </Row>
      <Row>
        Let's complete your onboarding first so we can check your renewal plan
        offer details.
      </Row>
      <Div mt={{ default: 3 }} width={{ default: 6 / 12 }} alignItems="center">
        <Button
          text="To Onboarding"
          onClick={() => history.push("/dashboard")}
        />
      </Div>
    </Div>
  );
};
interface ErrorSectionProps {
  errorMessage: string;
}
const ApprovalInfoError = ({ errorMessage }: ErrorSectionProps) => {
  return (
    <Div>
      <Row>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </Row>
    </Div>
  );
};

const UnapprovedSection = ({ history }: HistoryProps) => {
  return (
    <Div mt={{ default: 5 }} width={{ default: 1 }}>
      <Row justifyContent="center">
        <RenewalPlan
          justifyContent="center"
          alignItems="center"
          width={{ default: 9 / 12 }}
        >
          <SadCoin />
          <Row mt={{ default: 2 }} justifyContent="center">
            It looks like this bank account was not approved for a renewal plan.
          </Row>
          <Row justifyContent="center" mt={{ default: 3 }}>
            <Div width={{ default: 6 / 12 }}>
              What is a renewal plan? The Rentable Renewal Plan is a way for you
              to receive a portion of your security deposit back today. You will
              then pay it back to Rentable in monthly installments until it has
              been paid back in full.
            </Div>
          </Row>
          <Row justifyContent="center" mt={{ default: 3 }}>
            <Div width={{ default: 6 / 12 }} alignItems="center">
              Head back to the dashboard to link a different bank account if
              you'd like to be checked for approval again.
              <Div width={{ default: 6 / 12 }} mt={{ default: 3 }}>
                <Button
                  text="Back to dashboard"
                  onClick={() => history.push("/dashboard")}
                />
              </Div>
            </Div>
          </Row>
        </RenewalPlan>
      </Row>
    </Div>
  );
};

const EnrollmentSuccess = ({ history }: HistoryProps) => {
  return (
    <Div mt={{ default: 5 }} width={{ default: 1 }}>
      <Row justifyContent="center">
        <RenewalPlan
          justifyContent="center"
          alignItems="center"
          width={{ default: 6 / 12 }}
        >
          <HoorayCoin />
          <Row mt={{ default: 2 }} justifyContent="center">
            You've successfully enrolled in the Renewal Payment Plan!
          </Row>
          <Row justifyContent="center">
            You should see funds transferred into your bank account in 5
            business days!
          </Row>
          <Row justifyContent="center">
            <Button
              text="Back to Dashboard!"
              onClick={() => history.push("/dashboard")}
            />
          </Row>
        </RenewalPlan>
      </Row>
    </Div>
  );
};

interface DetailsProps {
  approvalInfo: RenewalPlanApprovalInfo;
  setSuccessfulEnrollment: () => void;
}
const EnrollmentDetailsSection = ({
  approvalInfo,
  setSuccessfulEnrollment,
}: DetailsProps) => {
  return (
    <Div mt={{ default: 5 }} width={{ lg: 6 / 12 }}>
      <Row justifyContent="center">
        <RenewalPlan
          justifyContent="center"
          alignItems="center"
          width={{ default: 1 }}
        >
          <Div
            width={{ sm: 1, md: 6 / 12, lg: 9 / 12 }}
            justifyContent="center"
          >
            <Div mt={{ default: 3 }}>
              <BoldText>
                You've been approved to receive a portion of your deposit back
                today!
              </BoldText>
            </Div>
          </Div>

          <Div width={{ sm: 1, md: 6 / 12, lg: 9 / 12 }}>
            <InfoBox width={{ sm: 1, md: 1, lg: 1 }} p={{ default: 3 }}>
              <Div alignItems="center">
                You will receive:
                <Price width={{ sm: 1 }}>
                  <span>
                    {currencyFormatter.format(approvalInfo.payout_amount)}
                  </span>
                </Price>
              </Div>
            </InfoBox>
          </Div>

          <Div width={{ sm: 1, md: 6 / 12, lg: 9 / 12 }} mt={{ default: 3 }}>
            <InfoBox p={{ default: 3 }}>
              <Div alignItems="center">
                Your Monthly Cost:
                <Price width={{ sm: 1 }}>
                  <span>
                    {currencyFormatter.format(
                      approvalInfo.monthly_payment_amount
                    )}
                  </span>
                </Price>
              </Div>
            </InfoBox>
            <MonthlyPaymentInfo
              alignItems="center"
              justifyContent="center"
              flexWrap="nowrap"
              pt={{ default: 1 }}
            >
              <CalendarIcon color={theme.colors.primary} />
              Total amount over {approvalInfo.approved_number_months} months:
              <MonthlyPaymentSpan>
                {currencyFormatter.format(approvalInfo.total_payback_amount)}
              </MonthlyPaymentSpan>
            </MonthlyPaymentInfo>
          </Div>
          <Row justifyContent="center" mt={{ default: 3 }} mb={{ default: 3 }}>
            <Div
              width={{ sm: 1, md: 6 / 12, lg: 9 / 12 }}
              justifyContent="center"
            >
              Your monthly payments will be deducted from your bank account on
              this day of each month until the deposit is paid back in full.
            </Div>
          </Row>
          <PayForEnrollment
            approvalInfo={approvalInfo}
            setSuccessfulEnrollment={setSuccessfulEnrollment}
          />
        </RenewalPlan>
      </Row>
    </Div>
  );
};

export default Details;
