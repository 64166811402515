import { useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import styled, { keyframes } from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import ToggleButton from "../../../components/baseComponents/ToggleButton";
import Input from "../../../components/baseComponents/Input";
import CurrencyInput from "../../../components/baseComponents/CurrencyInput";
import { CompanySettings } from "../../../components/global/ModelInterfaces";
import { changeCompanySettings } from "../../utils/companyUtils";
import { updateCompanySection } from "../../../store/nucleusCompanies";

const StyledRow = styled(Row)`
  margin-top: ${(props) => props.theme.space[2]};
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const StyledSaveMessage = styled(Div)`
  color: ${(props) => props.theme.colors.success};
  font-size: ${(props) => props.theme.font_size.headlineXS};
  animation: ${fadeOut} 4s forwards ease;
`;

const StyledErrorMessage = styled(Div)`
  color: ${(props) => props.theme.colors.danger};
  font-size: ${(props) => props.theme.font_size.headlineXS};
`;

interface Props {
  companySettings: CompanySettings;
}

const ChangeCompanySettingsForm = ({ companySettings }: Props) => {
  const dispatch = useAppDispatch();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleClick = (field: string, value: boolean | string) => {
    if (value === null || value === "") {
      setErrorMessage("Value for " + field + " cannot be empty.");
      return;
    }
    const updatedSettiing = {
      company: companySettings.company,
      field: field,
      value: value,
    };
    setErrorMessage("");
    setSuccessMessage("");

    const successCallback = (companySettingsData: any) => {
      dispatch(
        updateCompanySection(
          companySettingsData.company.uuid,
          "company_settings",
          companySettingsData
        )
      );
      setSuccessMessage("Successfully saved.");
    };

    const failureCallback = (err: any) => {
      // resets the input change if backend fails to update
      dispatch(
        updateCompanySection(
          companySettings.company.uuid,
          "company_settings",
          companySettings
        )
      );
      setErrorMessage("Error: " + err.data);
    };

    changeCompanySettings(updatedSettiing, successCallback, failureCallback);
  };

  return (
    <Div mt={{ default: 3 }}>
      <StyledRow>
        <Div width={{ lg: 8 / 12 }}>
          <span>Pays Processor Fee</span>
        </Div>
        <Div width={{ lg: 3 / 12 }}>
          <ToggleButton
            initialToggleState={companySettings.pays_processor_fee}
            onToggle={(val: boolean) => handleClick("pays_processor_fee", val)}
          />
        </Div>
      </StyledRow>
      <StyledRow>
        <Div width={{ lg: 8 / 12 }}>
          <span>Tenant Can Pay Monthly</span>
        </Div>
        <Div width={{ lg: 3 / 12 }}>
          <ToggleButton
            initialToggleState={companySettings.tenant_can_pay_monthly}
            onToggle={(val: boolean) =>
              handleClick("tenant_can_pay_monthly", val)
            }
          />
        </Div>
      </StyledRow>
      <StyledRow>
        <Div width={{ lg: 8 / 12 }}>
          <span>Pays Tenant Fee</span>
        </Div>
        <Div width={{ lg: 3 / 12 }}>
          <ToggleButton
            initialToggleState={companySettings.pays_tenant_fee}
            onToggle={(val: boolean) => handleClick("pays_tenant_fee", val)}
          />
        </Div>
      </StyledRow>
      <StyledRow>
        <Div width={{ lg: 8 / 12 }}>
          <span>Can Charge Fees</span>
        </Div>
        <Div width={{ lg: 3 / 12 }}>
          <ToggleButton
            initialToggleState={companySettings.can_charge_fees}
            onToggle={(val: boolean) => handleClick("can_charge_fees", val)}
          />
        </Div>
      </StyledRow>
      <StyledRow>
        <Div width={{ lg: 8 / 12 }}>
          <span>Requires Renter's Insurance</span>
        </Div>
        <Div width={{ lg: 3 / 12 }}>
          <ToggleButton
            initialToggleState={companySettings.requires_renters_insurance}
            onToggle={(val: boolean) =>
              handleClick("requires_renters_insurance", val)
            }
          />
        </Div>
      </StyledRow>
      <StyledRow>
        <Div width={{ lg: 8 / 12 }}>
          <span>Interested Party Required (Renter's Insurance)</span>
        </Div>
        <Div width={{ lg: 3 / 12 }}>
          <ToggleButton
            initialToggleState={
              companySettings.renters_insurance_interested_party_required
            }
            onToggle={(val: boolean) =>
              handleClick("renters_insurance_interested_party_required", val)
            }
          />
        </Div>
      </StyledRow>
      <StyledRow>
        <Div width={{ lg: 8 / 12 }}>
          <span>Auto-Link Landlords to Buildings</span>
        </Div>
        <Div width={{ lg: 3 / 12 }}>
          <ToggleButton
            initialToggleState={
              companySettings.auto_link_landlords_to_buildings
            }
            onToggle={(val: boolean) =>
              handleClick("auto_link_landlords_to_buildings", val)
            }
          />
        </Div>
      </StyledRow>
      <StyledRow>
        <Div width={{ lg: 8 / 12 }}>
          <span>Automatically Release Rent</span>
        </Div>
        <Div width={{ lg: 3 / 12 }}>
          <ToggleButton
            initialToggleState={companySettings.automatically_release_rent}
            onToggle={(val: boolean) =>
              handleClick("automatically_release_rent", val)
            }
          />
        </Div>
      </StyledRow>
      <StyledRow>
        <Div width={{ lg: 8 / 12 }}>
          <span>Send Certified Mail</span>
        </Div>
        <Div width={{ lg: 3 / 12 }}>
          <ToggleButton
            initialToggleState={companySettings.send_certified_mail}
            onToggle={(val: boolean) => handleClick("send_certified_mail", val)}
          />
        </Div>
      </StyledRow>
      <StyledRow>
        <Div width={{ lg: 8 / 12 }}>
          <span>Hide Renters' Insurance </span>
        </Div>
        <Div width={{ lg: 3 / 12 }}>
          <ToggleButton
            initialToggleState={companySettings.hide_renters_insurance}
            onToggle={(val: boolean) =>
              handleClick("hide_renters_insurance", val)
            }
          />
        </Div>
      </StyledRow>
      <StyledRow alignItems="center">
        <Div width={{ lg: 8 / 12 }}>
          <span>Max Payment Plan Months</span>
        </Div>
        <Div width={{ lg: 3 / 12 }}>
          <Input
            defaultValue={companySettings.max_payment_plan_months.toString()}
            onKeyDown={(e) => {
              handleClick("max_payment_plan_months", e.currentTarget.value);
            }}
            onBlur={(e) =>
              handleClick("max_payment_plan_months", e.currentTarget.value)
            }
          />
        </Div>
      </StyledRow>
      <StyledRow alignItems="center">
        <Div width={{ lg: 8 / 12 }}>
          <span>Transaction Fee Minimum</span>
        </Div>
        <Div width={{ lg: 4 / 12 }}>
          <CurrencyInput
            defaultValue={companySettings.transaction_fee_minimum.toString()}
            onKeyDown={(e) => {
              handleClick("transaction_fee_minimum", e.currentTarget.value);
            }}
            onBlur={(e) =>
              handleClick("transaction_fee_minimum", e.currentTarget.value)
            }
          />
        </Div>
      </StyledRow>
      <StyledRow alignItems="center">
        <Div width={{ lg: 8 / 12 }}>
          <span>Transaction Fee Limit</span>
        </Div>
        <Div width={{ lg: 4 / 12 }}>
          <CurrencyInput
            defaultValue={companySettings.transaction_fee_limit.toString()}
            onKeyDown={(e) => {
              handleClick("transaction_fee_limit", e.currentTarget.value);
            }}
            onBlur={(e) =>
              handleClick("transaction_fee_limit", e.currentTarget.value)
            }
          />
        </Div>
      </StyledRow>
      <StyledRow alignItems="center">
        <Div width={{ lg: 8 / 12 }}>
          <span>Property Manager Self-Fund Fee</span>
        </Div>
        <Div width={{ lg: 4 / 12 }}>
          <CurrencyInput
            defaultValue={companySettings.self_fund_fee.toString()}
            onKeyDown={(e) => {
              handleClick("self_fund_fee", e.currentTarget.value);
            }}
            onBlur={(e) => handleClick("self_fund_fee", e.currentTarget.value)}
          />
        </Div>
      </StyledRow>
      <StyledRow alignItems="center">
        <Div width={{ lg: 8 / 12 }}>
          <span>Check Fee Amount</span>
        </Div>
        <Div width={{ lg: 4 / 12 }}>
          <CurrencyInput
            defaultValue={companySettings.check_sending_fee.toString()}
            onKeyDown={(e) => {
              handleClick("check_sending_fee", e.currentTarget.value);
            }}
            onBlur={(e) =>
              handleClick("check_sending_fee", e.currentTarget.value)
            }
          />
        </Div>
      </StyledRow>
      <Row justifyContent="center">
        <Div>
          {successMessage && (
            <StyledSaveMessage>{successMessage}</StyledSaveMessage>
          )}
          {errorMessage && (
            <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
          )}
        </Div>
      </Row>
    </Div>
  );
};

export default ChangeCompanySettingsForm;
