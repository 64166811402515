import { useState } from "react";
import styled from "styled-components";
import Button from "../../../../../components/baseComponents/Button";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import Input from "../../../../../components/baseComponents/Input";
import LocationSelect from "../components/LocationSelect";
import HorizontalDivider from "../../../../../components/baseComponents/HorizontalDivider";
import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import { RentManagerLocation } from "../ModelInterfaces";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import { retrieveResourceList } from "../../../../utils/rentManagerUtils";
import BackArrow from "../../../../../images/BackArrow";

const StyledInstructions = styled(Div)`
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const StyledReturnButton = styled(Button)`
  background: none;
  color: ${(props) => props.theme.colors.black};
  justify-content: flex-start;
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const StyledSubtitle = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const ResourceList = () => {
  const [selectedLocation, setSelectedLocation] =
    useState<RentManagerLocation | null>(null);
  const [resourceURL, setResourceURL] = useState("");
  const [resourceList, setResourceList] = useState<Array<GenericObject> | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = () => {
    if (!selectedLocation || !resourceURL) {
      setErrorMessage(
        "You must choose a location and enter a resource endpoint."
      );
      return;
    }
    const successCallBack = (resourceList: Array<GenericObject>) => {
      setResourceList(resourceList);
      setLoading(false);
    };
    const failureCallback = () => {
      setErrorMessage(
        "Could not get the resource list with the provided endpoint and location."
      );
      setLoading(false);
    };
    setLoading(true);
    setErrorMessage("");
    setResourceList(null);
    retrieveResourceList(
      selectedLocation.uuid,
      resourceURL,
      successCallBack,
      failureCallback
    );
  };

  if (resourceList) {
    return (
      <Div>
        <Row
          mb={{ default: 3 }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Div width={{ lg: 6 / 12 }}>
            <StyledReturnButton onClick={() => setResourceList(null)}>
              <Row alignItems="center">
                <Div>
                  <BackArrow width="16" height="16" />
                </Div>
                Return to Form
              </Row>
            </StyledReturnButton>
          </Div>
          <StyledSubtitle justifyContent="center" width={{ lg: 4 / 12 }}>
            Total Resources: {resourceList.length}
          </StyledSubtitle>
        </Row>
        {resourceList.length > 0 ? (
          <>
            {resourceList.map((resource) => {
              return (
                <Row mb={{ default: 2 }}>
                  {JSON.stringify(resource, null, 1).replace(
                    /"([^"]+)":/g,
                    "$1:"
                  )}
                  <Div width={{ lg: 12 / 12 }} mt={{ lg: 2 }}>
                    <HorizontalDivider />
                  </Div>
                </Row>
              );
            })}
          </>
        ) : (
          <Div>No resources returned from query.</Div>
        )}
      </Div>
    );
  }

  return (
    <Div>
      <Row>
        <StyledInstructions mb={{ default: 3 }}>
          Choose Location to Get Resource List from:
        </StyledInstructions>
      </Row>
      <LocationSelect
        handleSelect={(selectedLocation: RentManagerLocation) => {
          setSelectedLocation(selectedLocation);
        }}
      />
      <Row mb={{ default: 3 }}>
        <Div width={{ default: 1 }}>
          <Input
            value={resourceURL}
            placeholderText="e.g. /Prospects"
            onChange={(e) => setResourceURL(e.target.value)}
            label="Resource Endpoint URL"
          />
        </Div>
      </Row>
      {errorMessage && (
        <Row justifyContent="center" mb={{ default: 3 }}>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Row>
      )}
      <Row>
        <Div width={{ default: 1 }}>
          <Button
            loading={loading}
            text="Retrieve Resource List"
            onClick={handleSubmit}
          />
        </Div>
      </Row>
    </Div>
  );
};

export default ResourceList;
